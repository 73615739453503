import api from '@/lib/services/general.service'
import { toast } from 'vue3-toastify';

export const state = {
  show: false,
  content: '',
  id: null,
  title: '',
  contact: null,
  timeline: null,
  timelines: [],
  timelinesIsLoading: true,
  timelineIsLoading: true,
}

export const getters = {
  show: x => x.show,
  content: x => x.content,
  id: x => x.id,
  title: x => x.title,
  contact: x => x.contact,
  timeline: x => x.timeline,
  timelines: x => x.timelines,
  isLoading: x => x.timelinesIsLoading,
  timelineIsLoading: x => x.timelineIsLoading
}

export const actions = {
  collapse({ commit }, request) {
    commit("SET_ISCOLLAPSE", request);
  },
  collapseAll({ commit }) {
    commit("SET_ISCOLLAPSE_TRUE_All");
  },
  async showSidePanel({ commit },) {
    commit("SHOW_SIDE_PANEL")
  },
  async fetchTimeline({ commit }, { id, content, title }) {
    commit("SET_TIMELINE_LOADING", true)
    commit("SET_ID", id)
    commit("SET_CONTENT", content)
    commit("SET_TITLE", title)
    return await api.getById(`timeline`, id, '').then(response => {
      if (response) {
        commit("SET_SIDE_PANEL_DATA", response.data)
        commit("SET_TIMELINE_LOADING", false)

      }
      return false;
    });
  },
  async fetchTimelines({ commit, rootGetters }, { id, content, title, contact }) {
    commit("SET_TIMELINES_LOADING", true)
    commit("SET_ID", id)
    commit("SET_CONTENT", content)
    commit("SET_TITLE", title)
    commit("SET_CONTACT", contact)
    if (title == "ME") {
      const user = rootGetters['user/user'];

      return await api.getWithPathById(`timeline`, user.email, 'mine').then(response => {
        if (response) {
          commit("SET_TIMELINE", response.data)
          commit("SET_TIMELINES_LOADING", false)
        }
        return false;
      });
    }
    else {
      return await api.getWithPathById(`timeline`, id, 'all').then(response => {
        if (response) {
          commit("SET_TIMELINE", response.data)
          commit("SET_TIMELINES_LOADING", false)
        }
        return false;
      });
    }
  },
  hideSidePanel({ commit }) {
    commit("HIDE_SIDE_PANEL")
  },
  async markAsRead({ commit, dispatch, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForMarkAsRead(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email read' : `${count} emails read`;
    if (count == 0) {
      message = request.length === 1
        ? 'Email already read'
        : `${request.length} emails already read`;

      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/markAsRead`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          commit("MARK_AS_READ", calculatedRequest);
          dispatch('timeline/markAsReadFromSidePanel', calculatedRequest, { root: true });
          return true;
        }
      } catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to change emails flags",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  },
  async markAsUnRead({ commit, dispatch, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForMarkAsUnread(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email read' : `${count} emails read`;
    if (count == 0) {
      message = request.length === 1
        ? 'Email already read'
        : `${request.length} emails already read`;

      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/markAsUnRead`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          commit("MARK_AS_UNREAD", calculatedRequest);
          dispatch('timeline/markAsUnreadFromSidePanel', calculatedRequest, { root: true });
          return true;
        }
      } catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to change emails flags",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  },

  async loadContact({ commit, dispatch, rootGetters, state }, id) {
    commit("SET_TIMELINES_LOADING", true)
    const calculateLoadContact = calculateForLoadContact(state, id);
    if (calculateLoadContact) {
      const contact = await api.getById(`contact`, id)
      await dispatch('fetchTimelines', { id: id, content: "content", title: contact.data.name, contact: contact.data });
      return false
    }
    else {
      return true
    }
  },
  async markAsImportant({ commit, dispatch, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForMarkAsImportant(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email marked important' : `${count} emails marked important`;
    if (count == 0) {
      message = request.length === 1 ? 'Email already important' : `${request.length} emails already important`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    } else {
      try {
        const response = await api.insert(`timeline/markAsImportant`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          commit("MARK_AS_IMPORTANT", calculatedRequest);
          dispatch('timeline/markAsImportantFromSidePanel', calculatedRequest, { root: true });
          return true;
        }
      } catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to change emails flags",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  },
  async markAsNotImportant({ commit, dispatch, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForMarkAsNotImportant(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email marked not important' : `${count} emails marked not important`;
    if (count == 0) {
      message = request.length === 1 ? 'Email already not important' : `${request.length} emails already not important`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/markAsNotImportant`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          commit("MARK_AS_NOT_IMPORTANT", calculatedRequest);
          dispatch('timeline/markAsNotImportantFromSidePanel', calculatedRequest, { root: true });
          return true;
        }
      } catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to change emails flags",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  },
  async toggleWaitingOn({ state, commit, dispatch }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculateCountAndUnseen = calculateToggleWaitingOn(state, request);
    let message = request.dueDate ? 'Email sets waiting-on' : "Email sets no waiting";
    try {
      const response = await api.insert(`timeline/toggleWaitingOn`, request);
      if (response) {
        setTimeout(() => {
          toast.update(toastId, {
            render: message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        commit("UPDATE_WAITING", request);
        dispatch('timeline/toggleWaitingOnTimelinesFromSidePanel', calculateCountAndUnseen, { root: true });
        return true;
      }
    } catch (error) {
      setTimeout(() => {
        toast.update(toastId, {
          render: error,
          autoClose: true,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      return false;

    }
  },
  async archiveTimeline({ commit, dispatch, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForArchive(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email archived' : `${count} emails archived`;
    if (count == 0) {
      message = request.length === 1
        ? 'Email already archived'
        : `${request.length} emails already archived`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: true,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/archive`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          commit("MARK_AS_ARCHIVE", calculatedRequest);
          dispatch('timeline/archiveTimelinesFromSidePanel', calculatedRequest, { root: true });
          return true;
        }
      }
      catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to change emails folder",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  },
  async deleteTimeline({ state, commit, dispatch }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForDelete(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email deleted' : `${count} Emails deleted`;
    if (count == 0) {
      message = "emails already deleted"
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/moveToBin`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          commit("MOVE_TO_BIN", calculatedRequest);
          dispatch('timeline/deleteTimelinesFromSidePanel', calculatedRequest, { root: true });
          return true;
        }
      } catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to delete emails",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  }
}
function calculateForArchive(state, request) {
  const timelines = state.timelines.filter(item => request.includes(item.id) && !item.isArchive);
  let ids = timelines.map(i => i.id);
  let unSeenCount = 0;
  timelines.forEach(item => {
    if (item.emailMessages) {
      unSeenCount += item.emailMessages.filter(ti => !ti.seen).length;
    }
  });
  let contactId = state.id
  return { ids, unSeenCount, contactId };
}

function calculateForDelete(state, request) {
  const timelines = state.timelines.filter(item => request.includes(item.id));
  let ids = timelines.map(i => i.id);

  let unSeenCount = 0;
  if (Array.isArray(timelines)) {
    timelines.forEach(item => {
      if (item.emailMessages && Array.isArray(item.emailMessages)) {
        unSeenCount += item.emailMessages.filter(ti => !ti.seen).length;
      }
    });
  }
  let importantCount = timelines.filter(i => i.isImportant).length;
  let waitingOnCount = timelines.filter(i => i.waitingOn).length;
  let overdueCount = timelines.filter(i => i.dueDate && new Date(i.dueDate) <= new Date()).length;
  let contactId = state.id

  return { ids, unSeenCount, importantCount, waitingOnCount, overdueCount, contactId };
}
function calculateForMarkAsRead(state, request) {
  const timelinesInbox = state.timelines.filter(item => request.includes(item.id) && !item.seen);
  let ids = timelinesInbox.map(i => i.id);
  let inboxCount = 0;
  timelinesInbox.forEach(item => {
    if (item.emailMessages) {
      inboxCount += item.emailMessages.filter(ti => !ti.seen).length;
    }
  });
  let contactId = state.id
  return { ids, inboxCount, contactId };
}
function calculateForMarkAsUnread(state, request) {
  const timelinesInbox = state.timelines.filter(item => request.includes(item.id) && item.seen);
  let ids = timelinesInbox.map(i => i.id);
  let inboxCount = 0;
  timelinesInbox.forEach(item => {
    if (item.emailMessages) {
      inboxCount += item.emailMessages.filter(ti => ti.seen).length;
    }
  });
  let contactId = state.id
  return { ids, inboxCount, contactId };
}

function calculateForLoadContact(state, id) {
  if (state.id && state.id === id) {
    return false;
  }
  else {
    return true;
  }
}
function calculateForMarkAsNotImportant(state, request) {
  const importantTimelines = state.timelines.filter(item => request.includes(item.id) && item.isImportant);
  let ids = importantTimelines.map(i => i.id);
  return { ids };
}
function calculateForMarkAsImportant(state, request) {
  const importantTimelines = state.timelines.filter(item => request.includes(item.id) && !item.isImportant);
  let ids = importantTimelines.map(i => i.id);
  return { ids };
}
function calculateToggleWaitingOn(s, request) {
  const timeline = s.timelines.find(t => t.id === request.timelineId);
  if (!timeline) return { addWaitingOn: false, addOverDue: false, removeOverDue: false };
  let addWaitingOn = !timeline.waitingOn;
  let addOverDue = false;
  let removeOverDue = false;
  const currentDateTime = new Date();
  const dueDateTime = request.dueDate ? new Date(request.dueDate) : null;
  const wasOverDue = timeline.dueDate && new Date(timeline.dueDate) <= currentDateTime;

  if (dueDateTime) {
    if (addWaitingOn) {
      addOverDue = dueDateTime <= currentDateTime;
    } else {
      if (dueDateTime > currentDateTime) {
        removeOverDue = true;
      }
    }
  } else {
    removeOverDue = wasOverDue;
  }
  return { addWaitingOn, addOverDue, removeOverDue };
}

export const mutations = {
  REMOVE(s, ids) {
    s.timelines = s.timelines.filter(item => !ids.includes(item.id));
  },
  MOVE_TO_BIN(s, request) {
    s.timelines = s.timelines.filter(i => !request.ids.some(id => id === i.id));
  },
  MARK_AS_READ(s, request) {
    request.ids.forEach(id => {
      const timeline = s.timelines.find(t => t.id === id);
      if (timeline) {
        timeline.emailMessages.forEach(e => e.seen = true);
        timeline.seen = true;
      }
    })
  },
  MARK_AS_UNREAD(s, request) {
    request.ids.forEach(id => {
      const timeline = s.timelines.find(t => t.id === id);
      if (timeline) {
        timeline.emailMessages.forEach(e => e.seen = false);
        timeline.seen = false;
      }
    })
  },
  MARK_AS_ARCHIVE(s, request) {
    s.timelines = s.timelines.filter(i => !request.ids.some(id => id === i.id));
  },
  SHOW_SIDE_PANEL(x) {
    x.show = true
  },
  SET_CONTENT(x, content) {
    x.content = content
  },
  SET_ID(x, id) {
    x.id = id
  },
  SET_TITLE(x, title) {
    x.title = title
  },
  SET_CONTACT(x, contact) {
    x.contact = contact
  },
  SET_TIMELINE(x, timelines) {
    x.timelines = timelines
  },
  SET_SIDE_PANEL_DATA(x, timeline) {
    x.timeline = timeline
  },
  HIDE_SIDE_PANEL(x) {
    x.show = false
  },
  SET_TIMELINES_LOADING(s, isLoading) {
    s.timelinesIsLoading = isLoading;
  },
  SET_TIMELINE_LOADING(s, isLoading) {
    s.timelineIsLoading = isLoading;
  },
  UPDATE_IMPORTANT(s, data) {
    data.response.forEach(response => {
      const timeline = s.timelines.find(t => t.id === response.id);
      if (timeline) {
        timeline.isImportant = data.important;
      }
    })
  },

  SET_ISCOLLAPSE(s, request) {
    const index = s.timelines.findIndex(item => item.id === request.id);
    if (index !== -1) {
      s.timelines[index].isCollapsed = !s.timelines[index].isCollapsed;

    };

  },
  SET_ISCOLLAPSE_TRUE_All(state) {
    state.timelines.forEach((timeline) => {
      timeline.isCollapsed = true;
    });
  },
  SET_COUNT_OP(s, { op, model }) {
    const { ids, unSeenCount, seenCount } = model;
    switch (op) {
      case 'archive':
        s.timelines = s.timelines.filter(i => !ids.some(id => id === i.id));
        s.contact.totalCount -= ids.length;
        s.contact.unSeenCount -= unSeenCount;
        break;
      case 'moveToBin':
        s.timelines = s.timelines.filter(i => !ids.some(id => id === i.id));
        s.contact.totalCount -= ids.length;
        s.contact.unSeenCount -= unSeenCount;
        break;
      case 'markAsImportant':
        ids.forEach(id => {
          const timeline = s.timelines.find(t => t.id === id);
          if (timeline) {
            timeline.isImportant = true;
          }
        })
        break;
      case 'markAsNotImportant':
        ids.forEach(id => {
          const timeline = s.timelines.find(t => t.id === id);
          if (timeline) {
            timeline.isImportant = false;
          }
        })
        break;
      case 'markAsRead':
        s.contact.unSeenCount -= seenCount;
        ids.forEach(id => {
          const timeline = s.timelines.find(t => t.id === id);
          if (timeline) {
            timeline.emailMessages.forEach(e => e.seen = true);
            timeline.seen = true;
          }
        })
        break;
      case 'markAsUnread':
        s.contact.unSeenCount += unSeenCount;
        ids.forEach(id => {
          const timeline = s.timelines.find(t => t.id === id);
          if (timeline) {
            timeline.emailMessages.forEach(e => e.seen = false);
            timeline.seen = false;
          }
        })
        break;
    }
  },
  MARK_AS_IMPORTANT(s, request) {
    request.ids.forEach(id => {
      const timeline = s.timelines.find(t => t.id === id);
      if (timeline) {
        timeline.isImportant = true;
      }
    })
  },
  MARK_AS_NOT_IMPORTANT(s, request) {
    request.ids.forEach(id => {
      const timeline = s.timelines.find(t => t.id === id);
      if (timeline) {
        timeline.isImportant = false;
      }
    })
  },
  UPDATE_WAITING(s, request) {
    const timeline = s.timelines.find(t => t.id === request.timelineId);
    if (timeline) {
      timeline.waitingOn = !timeline.waitingOn;
      timeline.dueDate = request.dueDate
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}