import api from '@/lib/services/general.service'
import { toast } from 'vue3-toastify';

export const state = {
    emails: {
        items: [],
        index: 0,
        totalCount: 0,
        totalPages: 0,
        hasPreviousPage: 0,
        hasNextPage: 0,
    },
    spamEmails: {
        items: [],
        index: 0,
        totalCount: 0,
        totalPages: 0,
        hasPreviousPage: 0,
        hasNextPage: 0,
    },
    draftEmails: {
        items: [],
        index: 0,
        totalCount: 0,
        totalPages: 0,
        hasPreviousPage: 0,
        hasNextPage: 0,
    },
    emailsBody: [],
    isLoading: false,


}
export const getters = {
    emails: s => s.emails,
    spamEmails: s => s.spamEmails,
    draftEmails: s => s.draftEmails,
    isLoading: s => s.isLoading,
    getEmailBodyById: (s) => (uId) => {
        return s.emailsBody.find(item => item.uId === uId);
    }
};
export const actions = {
    async postEmail({ commit }, email) {
        const toastId = toast.loading(
            'Sending email...',
            {
                theme: 'email',
            }
        );
        const response = await api.insert('email', email)
        if (response) {
            setTimeout(() => {
                toast.update(toastId, {
                    render: 'Email Sent',
                    autoClose: true,
                    closeOnClick: true,
                    closeButton: true,
                    theme: 'email',
                    isLoading: false,
                });
                toast.done(toastId);
            });
        }
    },
    async postEmailReply({ commit }, {id,email}) {
        const toastId = toast.loading(
            'Sending email...',
            {
                theme: 'email',
            }
        );
        const response = await api.insertWithPath('email', id +'/reply', email)
        if (response) {
            setTimeout(() => {
                toast.update(toastId, {
                    render: 'Email Sent',
                    autoClose: true,
                    closeOnClick: true,
                    closeButton: true,
                    theme: 'email',
                    isLoading: false,
                });
                toast.done(toastId);
            });
        }
    },
    async fetchEmails({ commit }, requestData) {
        return await api.get(`email`, requestData).then(response => {
            if (response) {
                commit("SET_EMAILS", response.data);
            }
            return true;
        });
    },
    async fetchSpamsEmails({ commit }, requestData) {
        commit("SET_LOADING", true);
        return await api.get(`email/spam`, requestData).then(response => {
            if (response) {
                commit("SET_SPAM_EMAILS", response.data);
            }
            return true;
        });
    },
    async fetchDraftsEmails({ commit }, requestData) {
        return await api.get(`email/draft`, requestData).then(response => {
            if (response) {
                commit("SET_DRAFT_EMAILS", response.data);
            }
            return true;
        });
    },
    async eraseEmail({ commit }, requestData) {
        const request = {
            emailIds: [],
            providerEmailIds: {},
        };
        requestData.forEach((email) => {
            if (email.id && email.id !== "00000000-0000-0000-0000-000000000000") {
                request.emailIds.push(email.id);
            }
            if (email.provider !== null && email.uId) {
                const provider = email.provider;
                if (!request.providerEmailIds[provider]) {
                    request.providerEmailIds[provider] = [];
                }
                request.providerEmailIds[provider].push(email.uId.toString());
            }
        });
        // return await api.get(`email/draft`, requestData).then(response => {
        //     if (response) {
        //         commit("SET_DRAFT_EMAILS", response.data);
        //     }
        //     return true;
        // });
    },
    async fetchEmailBody({ commit }, id) {
        return await api.getWithPathById(`email`, id, 'body').then(response => {
            if (response) {
                commit("SET_EMAIL_BODY", response.data);
            }
            return true;
        });
    }
}
export const mutations = {
    SET_EMAILS(s, emails) {
        s.emails = emails;
        s.isLoading = false;
    },
    SET_SPAM_EMAILS(s, emails) {
        s.spamEmails = emails;
        s.isLoading = false;
    },
    SET_LOADING(s, loading) {
        s.isLoading = loading;

    },
    SET_DRAFT_EMAILS(s, emails) {
        s.draftEmails = emails;
        s.isLoading = false;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
};