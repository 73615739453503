import api from '@/lib/services/general.service';
import { toast } from 'vue3-toastify';

const initialState = (important = false, isPreset = false) => ({
  notes: {
    items: [],
    index: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: 0,
    hasNextPage: 0,
  },
  requestData: {
    page: 1,
    pageSize: 50,
    order: 1,
    search: '',
    important: false,
    isPreset: false,
  },
  presetNotes: {
    items: [],
    index: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: 0,
    hasNextPage: 0,
  },
  totalCount: 0,
  importantCount: 0,
  presetCount: 0,
});
export const state = initialState();
export const getters = {
  isLoading: s => s.isLoading,
  totalCount: s => s.totalCount,
  importantCount: s => s.importantCount,
  presetCount: s => s.presetCount,
  pagesLoaded: s => s.pagesLoaded,
  important: s => s.requestData.important,
  isPreset: s => s.requestData.isPreset,
  presetNotes: s => s.presetNotes,
  notes: s => s.notes,
  requestData: s => s.requestData,
};

export const actions = {
  async fetchNotesAndCounts({ dispatch }, newRequestData) {
    await Promise.all([
      dispatch('fetchNotes', newRequestData),
      dispatch('fetchNoteCounts'),
      dispatch('fetchPresets'),
    ]);
  },
  async fetchPresets({ commit, state }) {
    let requestData = {
      page: 1,
      pageSize: 5000,
      order: 1,
      search: '',
      isPreset: true,
    }
    try {
      let response = await api.get('note', requestData);
      if (response) {
        commit("SET_PRESETS", response.data);
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  },
  async fetchNotes({ commit, state }, newRequestData) {
    let requestData = null;
    if (newRequestData === null) {
      requestData = {
        page: 1,
        pageSize: 50,
        order: 1,
        search: '',
        important: state.requestData.important,
        isPreset: state.requestData.isPreset,
      }
    } else {
      requestData = newRequestData;
    }
    commit('SET_REQUEST_DATA', requestData);
    commit('SET_LOADING', true);
    try {
      await fetchNotesWithRetry(commit, requestData, 0);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchNoteCounts({ commit }) {
    try {
      const response = await api.get('note/count', {});
      if (response) {
        commit("SET_COUNT", response.data);
      }
      return true;
    } catch (error) {
      console.error('Error fetching note counts:', error);
      return false;
    }
  },

  async addNote({ commit, dispatch, state }, note) {
    const toastId = toast.loading(
      'Please wait...',
      {
        theme: 'note',
      }
    );
    try {
      const response = await api.insert('note', note);
      if (response) {
        if (note.timelineId) {
          dispatch('timeline/addNoteIntoTimeline', response.data, { root: true });
        }
        setTimeout(() => {
          toast.update(toastId, {
            render: 'Note added',
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'note',
            isLoading: false,
          });
          toast.done(toastId);
        });
        dispatch('fetchNotesAndCounts', null);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error adding note:', error);
      return false;
    }
  },

  async markAsImportant({ commit, state, dispatch }, request) {
    const toastId = toast.loading(
      'Please wait...',
      {
        theme: 'note',
      }
    );
    const calculatedRequest = calculateForMarkAsImportant(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Note sets as important' : `${count} notes sets as important`;
    if (count == 0) {
      message = request.length === 1
        ? 'Note already important'
        : `${request.length} notes already important`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'note',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    } else {
      try {
        const response = await api.insert('note/markAsImportant', { ids: calculatedRequest.ids });
        if (response) {
          commit("UPDATE_NOTES", response.data);
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              isLoading: false,
              theme: 'note'
            });
            toast.done(toastId);
          });
          dispatch('fetchNoteCounts')
          return true;
        }
        return false;
      } catch (error) {
        console.error('Error marking note as important:', error);
        return false;
      }
    }
  },

  async markAsNotImportant({ commit, state, dispatch }, request) {
    const toastId = toast.loading(
      'Please wait...',
      {
        theme: 'note',
      }
    );
    const calculatedRequest = calculateForMarkAsNotImportant(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Note sets as not important' : `${count} notes sets as not important`;
    if (count == 0) {
      message = request.length === 1
        ? 'Note already not important'
        : `${request.length} notes already not important`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'note',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    }
    else {
      try {
        const response = await api.insert('note/markAsNotImportant', { ids: calculatedRequest.ids });
        if (response) {
          commit("UPDATE_NOTES", response.data);
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'note',
              isLoading: false,
            });
            toast.done(toastId);
          });
          dispatch('fetchNoteCounts')
          return true;
        }
        return false;
      } catch (error) {
        console.error('Error marking note as not important:', error);
        return false;
      }
    }
  },

  async deleteNote({ commit, dispatch, state }, request) {
    const count = request.length
    let message = ''
    const toastId = toast.loading(
      'Please wait...',
      {
        theme: 'note',
      }
    );
    if (count === 1) {
      message = 'Note deleted'
    } else {
      message = count + ' notes deleted'
    }
    try {
      const response = await api.deleteWithRequest('note/delete', { ids: request });
      if (response.data) {
        setTimeout(() => {
          toast.update(toastId, {
            render: message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'note',
            isLoading: false,
          });
          toast.done(toastId);
        });
        commit("REMOVE_NOTES", request);
        if (request.length > 1 && state.notes.hasNextPage == true) {
          dispatch('fetchNotesAndCounts', null);
        }
        dispatch('fetchNoteCounts')
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error deleting note:', error);
      return false;
    }
  },
  removePage({ state }, page) {
    state.pagesLoaded.delete(page);
  },

  async editNote({ commit, dispatch }, patchModel) {
    const toastId = toast.loading(
      'Please wait...',
      {
        theme: 'note',
      }
    );
    try {
      const response = await api.patch('note', patchModel[0], patchModel[1]);
      if (response) {
        commit("UPDATE_NOTE", response.data);
        setTimeout(() => {
          toast.update(toastId, {
            render: "Note updated",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'note',
            isLoading: false,
          });
          toast.done(toastId);
        });
        dispatch('fetchNoteCounts')
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error editing note:', error);
      return false;
    }
  },
};

async function fetchNotesWithRetry(commit, requestData, retryCount) {
  try {
    let response = null;
    let requestParams = { ...requestData };
    if (requestParams.important === false) {
      delete requestParams.important;
    }
    if (requestParams.isPreset === false) {
      delete requestParams.isPreset;
    }
    response = await api.get('note', requestParams);
    if (response) {
      commit("SET_NOTES", response.data);
      commit('SET_LOADING', false);
    }
  } catch (error) {
    console.error('Error fetching notes:', error);
    if (retryCount < 3) {
      await new Promise(resolve => setTimeout(resolve, 10000));
      await fetchNotesWithRetry(commit, requestData, retryCount + 1);
    } else {
      console.error('Max retries reached. Failed to fetch notes.');
    }
  }
}
function calculateForMarkAsImportant(state, request) {
  const notes = state.notes.items.filter(item => request.includes(item.id) && !item.isImportant);
  let ids = notes.map(i => i.id);
  return { ids };
}
function calculateForMarkAsNotImportant(state, request) {
  const notes = state.notes.items.filter(item => request.includes(item.id) && item.isImportant);
  let ids = notes.map(i => i.id);
  return { ids };
}

export const mutations = {
  RESET_STATE(state, important) {
    Object.assign(state, initialState(important));
  },
  SET_PRESETS(s, data) {
    s.presetNotes = data.items;
  },
  SET_NOTES(s, data) {
    s.notes = data;
  },
  SET_LOADING(s, isLoading) {
    s.isLoading = isLoading;
  },
  SET_REQUEST_DATA(s, data) {
    s.requestData.page = data.page
    s.requestData.pageSize = data.pageSize
    s.requestData.order = data.order
    s.requestData.search = data.search
    s.requestData.important = data.important
    s.requestData.isPreset = data.isPreset
  },

  SET_COUNT(s, response) {
    s.totalCount = response.totalCount;
    s.importantCount = response.importantCount;
    s.presetCount = response.presetCount;
  },

  ADD_NOTE(s, note) {
    if (note.isPreset) {
      s.presetCount += 1
    }
    if (note.isImportant) {
      s.importantCount += 1
    }
    s.totalCount += 1
    s.notes.items.push(note)
  },
  UPDATE_NOTE(s, response) {
    const note = s.notes.items.find(note => note.id === response.id);
    if (note) {
      note.content = response.content
      note.title = response.title
      note.isImportant = response.isImportant
      note.isPreset = response.isPreset
    }
  },
  UPDATE_NOTES(s, responses) {
    responses.forEach(response => {
      const note = s.notes.items.find(note => note.id === response.id);
      if (note) {
        note.content = response.content
        note.title = response.title
        note.isImportant = response.isImportant
        note.isPreset = response.isPreset
      }
    });
  },
  REMOVE_NOTES(s, ids) {
    s.notes.items = s.notes.items.filter(item => !ids.includes(item.id));
    let totalCount = s.notes.items.length;
    let deletedImportantCount = 0
    s.notes.items = s.notes.items.map(item => {
      if (ids.includes(item.id)) {
        if (item.isImportant) {
          deletedImportantCount++;
        }
        return { ...item, isDeleted: true };
      }
      return item;
    });
    s.notes.totalCount -= ids.length;
    s.totalCount -= ids.length;
    s.notes.totalPages = Math.ceil(totalCount / s.requestData.pageSize);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
