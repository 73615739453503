import api from '@/lib/services/general.service'

const initialState = () => ({
  allEmailSync: {
    totalEmails: 0,
    estimetedTime: 360,
    isLoading: true,
  },
  loadingPercentage: "0%",
  elapsedTime: 0,
  syncTimer: null,

});

export const state = initialState();

export const getters = {
  allEmailSync: s => s.allEmailSync,
  syncAllEmailInProgress: s => s.allEmailSync.isLoading,
  loadingPercentage: s => s.loadingPercentage,
};
const calculateLoadingPercentage = (elapsedTime, estimatedTime) => {
  if (estimatedTime === 0) return "0%";
  const percentage = Math.min((elapsedTime / estimatedTime) * 100, 100);
  return `${percentage.toFixed(0)}%`;
};

const waitForRequiredResponses = async () => {
  let contactSuccess = false;

  while (!contactSuccess) {
    try {
      if (!contactSuccess) {
        const contactResponse = await api.get(`timeline/count`, {});
        if (contactResponse?.status === 200) {
          contactSuccess = true;
        } else {
        }
      }

      if (!contactSuccess) {
        console.log("🔄 Waiting before retrying...");
        await new Promise(resolve => setTimeout(resolve, 60000)); // Wait 5 seconds before checking again
      }
    } catch (error) {
      console.error("⚠️ Error while fetching required responses:", error);
      await new Promise(resolve => setTimeout(resolve, 60000)); // Wait before retrying if there's an error
    }
  }
};
export const actions = {

  async firstSync({ commit, dispatch, getters }) {
    const response = await api.get('sync/allEmailSync', {});
    if (response) {
      commit('SET', response.data);
      const estimatedTime = response.data.estimetedTime;
      if (estimatedTime > 0) {
        commit('START_TIMER', estimatedTime);
      }
    }
    const interval = setInterval(async () => {
      const syncState = getters.syncAllEmailInProgress;
      if (syncState) {
        const response = await api.get('sync/allEmailSync', {});
        if (response.data.isLoading) {
          commit("SET", response.data)
        }
        else {
          clearInterval(interval); // Stop checking sync
          await waitForRequiredResponses();
          commit("STOP_TIMER");
          commit("SET", response.data);
          window.location.reload();

        }
      }
      else {
        clearInterval(interval);
      }
    }, 35000);
  },
};

const checkTimelineCount = async () => {
  const interval = setInterval(async () => {
    try {
      const response = await api.get('timeline/count', {});
      if (response) {
        clearInterval(interval);
        window.location.reload();
      }
    } catch (error) {
    }
  }, 30000); // Retry every 5 seconds
};
export const mutations = {
  SET(s, syncResponse) {
    s.allEmailSync = syncResponse;
  },
  START_TIMER(s, estimatedTime) {
    if (s.syncTimer) clearInterval(s.syncTimer); // Prevent duplicate timers

    s.syncTimer = setInterval(() => {
      if (s.elapsedTime < estimatedTime) {
        s.elapsedTime += 1;
        s.loadingPercentage = calculateLoadingPercentage(s.elapsedTime, estimatedTime);
      } else {
        clearInterval(s.syncTimer);
        s.syncTimer = null;
        s.loadingPercentage = "100%";
      }
    }, 1000);
  },
  STOP_TIMER(s) {
    if (s.syncTimer) {
      clearInterval(s.syncTimer);
      s.syncTimer = null;
    }
  }
}
export default {
  state,
  getters,
  actions,
  mutations
};