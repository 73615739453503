import api from '@/lib/services/general.service'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { toast } from 'vue3-toastify';

const $toast = useToast();

const initialState = (archived = false, deleted = false, important = false, waitingOn = false, overdue = false, folder = null) => ({
  timelines: {
    items: [],
    index: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: 0,
    hasNextPage: 0,
  },
  requestData: {
    page: 1,
    pageSize: 50,
    order: 1,
    search: '',
    archived: false,
    deleted: false,
    important: false,
    waitingOn: false,
    overdue: false,
    folder: null,
  },
  timelineCounts: {
    totalCount: 0,
    unSeenCount: 0,
    waitingOnCount: 0,
    importantCount: 0,
    overdueCount: 0,
    binCount: 0,
    archiveCount: 0,
    archiveUnSeenCount: 0,
    sentCount: 0,
  },
  contactsFilter: [],
  isLoading: true,
  isContactsFilterLoading: true,
  isAllEmailsSynced: false,
  contactsFilterLoadingError: null,

});

export const state = initialState();

export const getters = {
  contactsFilter: s => s.contactsFilter.filter(contact => contact.totalCount > 0),
  contactsFilterFirstId: (state) => {
    const firstValidContact = state.contactsFilter.find(contact => contact.totalCount > 0);
    return firstValidContact ? firstValidContact.id : null;
  }, timelines: s => s.timelines,
  isLoading: s => s.isLoading,
  contactsFilterLoadingError: s => s.contactsFilterLoadingError,
  isAllEmailsSynced: s => s.isAllEmailsSynced,

  isContactsFilterLoading: s => s.isContactsFilterLoading,
  getTimelineById: (s) => (id) => {
    return s.timelines.items.find(item => item.id === id);
  },
  timelineCounts: s => s.timelineCounts,
  requestData: s => s.requestData,
  archived: s => s.requestData.archived,
  deleted: s => s.requestData.deleted,
  important: s => s.requestData.important,
  waitingOn: s => s.requestData.waitingOn,
  overdue: s => s.requestData.overdue,
  folder: s => s.requestData.folder,

};

export const actions = {
  updateRequestData({ commit, dispatch }, requestData) {
    dispatch('fetchTimelines', requestData);
  },
  addTaskIntoTimeline({ commit }, task) {
    commit("ADD_TASK", task);
  },
  collapse({ commit }, request) {
    commit("SET_ISCOLLAPSE", request);
  },
  collapseAll({ commit }) {
    commit("SET_ISCOLLAPSE_TRUE_All");
  },
  fetchCount({ dispatch }) {
    dispatch('fetchTimelineCount');
  },
  archiveTimelinesFromSidePanel({ commit }, request) {
    commit("ARCHIVE_TIMELINES_FROM_SIDE_PANEL", request);
  },
  deleteTimelinesFromSidePanel({ commit }, request) {
    commit("DELETE_TIMELINES_FROM_SIDE_PANEL", request);
  },
  markAsImportantFromSidePanel({ commit }, request) {
    commit("MARK_AS_IMPORTANT_FROM_SIDE_PANEL", request);
  },
  markAsNotImportantFromSidePanel({ commit }, request) {
    commit("MARK_AS_NOT_IMPORTANT_FROM_SIDE_PANEL", request);
  },
  markAsReadFromSidePanel({ commit }, request) {
    commit("MARK_AS_READ_FROM_SIDE_PANEL", request);
  },
  markAsUnreadFromSidePanel({ commit }, request) {
    commit("MARK_AS_UNREAD_FROM_SIDE_PANEL", request);
  },
  toggleWaitingOnTimelinesFromSidePanel({ commit }, request) {
    commit("TOGGLE_WAITINGON_TIMELINES_FROM_SIDE_PANEL", request);
  },
  fetchContact({ dispatch }) {
    dispatch('fetchContactsFilter');
  },
  addNoteIntoTimeline({ commit }, note) {
    commit("ADD_NOTE", note);
  },
  addEventIntoTimeline({ commit }, newEvent) {
    commit("ADD_EVENT", newEvent);
  },

  // we need method for removing
  async fetchTimeline({ commit, dispatch, rootGetters }, timelineId) {
    const user = rootGetters['user/user'];

    try {
      return await api.getById(`timeline`, timelineId).then(response => {
        if (response) {
          commit("SET_TIMELINE", { timeline: response.data, email: user.email })
          //dispatch('fetchTimelineCount')
        }
        return true
      })
    } catch (err) {
      commit("SET_ERROR", err)
      return false
    }
  },
  async fetchContactsFilter({ commit, rootGetters }) {
    const user = rootGetters['user/user'];
    try {
      return await api.get(`timeline/contactFilter`, { email: user.email }).then(response => {
        if (response) {
          commit("SET_CONTACTS_FILTER", response.data)
        }
        return true
      })
    } catch (err) {
      commit("SET_ERROR_FOR_CONTACT", err)
      return false
    }
  },
  async fetchEmailBody({ commit }, { timelineId, emailIds }) {
    return await api.getEmailBody(`email/body`, { emailId: emailIds }).then(response => {
      if (response) {
        commit("SET_EMAILDETAIL", { timelineId: timelineId, emailId: emailIds, data: response.data });
      }
      return true;
    })
  },

  async archiveTimeline({ commit, rootGetters, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForArchive(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email archived' : `${count} emails archived`;
    if (count == 0) {
      message = request.length === 1
        ? 'Email already archived'
        : `${request.length} emails already archived`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: true,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/archive`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          const user = rootGetters['user/user'];
          commit("MARK_AS_ARCHIVE", { request: calculatedRequest, user: user });
          return true;
        }
      }
      catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to change emails folder",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  },
  async unArchiveTimeline({ commit, rootGetters, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForUnArchive(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email moved to inbox' : `${count} emails moved to inbox`;
    if (count == 0) {
      message = request.length === 1
        ? 'Email already in inbox'
        : `${request.length} emails already in inbox`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: true,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/unArchive`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          const user = rootGetters['user/user'];
          commit("MARK_AS_UNARCHIVE", { request: calculatedRequest, user: user });
          return true;
        }
      }
      catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to change emails folder",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  },
  async deleteTimeline({ commit, rootGetters, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForDelete(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email deleted' : `${count} Emails deleted`;
    if (count == 0) {
      message = "emails already deleted"
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/moveToBin`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          const user = rootGetters['user/user'];
          commit("MOVE_TO_BIN", { request: calculatedRequest, user: user });
          return true;
        }
      } catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to delete emails",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  },
  async eraseTimeline({ commit }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const count = request.ids.length
    let message = count === 1 ? 'Email deleted forever' : `${count} emails deleted forever`;
    try {
      const response = await api.deleteWithRequest(`timeline/erase`, request);
      if (response) {
        setTimeout(() => {
          toast.update(toastId, {
            render: message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        commit("ERASE_EMAILS", request.ids );
        return true;
      }
    } catch (error) {
      setTimeout(() => {
        toast.update(toastId, {
          render: "Failed to erase emails",
          autoClose: true,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      return true;
    }
  },
  async moveToInbox({ commit, dispatch, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForMoveToInbox(state, request);

    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email moved to inbox'
    } else {
      message = count + ' emails moved to inbox'
    }

    return await api.insert(`timeline/moveToInbox`, request)
      .then((response) => {
        if (response) {
          commit("REMOVE", request.ids);
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsImportant({ commit, dispatch, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForMarkAsImportant(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email marked important' : `${count} emails marked important`;
    if (count == 0) {
      message = request.length === 1 ? 'Email already important' : `${request.length} emails already important`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/markAsImportant`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          commit("MARK_AS_IMPORTANT", calculatedRequest);
          return true;
        }
      } catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to change emails flags",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  },
  async markAsNotImportant({ commit, dispatch, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForMarkAsNotImportant(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email marked not important' : `${count} emails marked not important`;
    if (count == 0) {
      message = request.length === 1 ? 'Email already not important' : `${request.length} emails already not important`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/markAsNotImportant`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          commit("MARK_AS_NOT_IMPORTANT", calculatedRequest);
          return true;
        }
      } catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to change emails flags",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  },

  async toggleRead({ commit, dispatch }, timelineId) {
    return api.toggle(`timeline/${timelineId}/toggleRead`)
      .then((response) => {
        if (response) {
          commit("UPDATE", timelineId);
          $toast.open({
            message: 'Email updated status',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsRead({ commit, rootGetters, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForMarkAsRead(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email read' : `${count} emails read`;
    if (count == 0) {
      message = request.length === 1
        ? 'Email already read'
        : `${request.length} emails already read`;

      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/markAsRead`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          const user = rootGetters['user/user'];
          commit("MARK_AS_READ", { request: calculatedRequest, user: user });
          return true;
        }
      } catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to change emails flags",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return true;
      }
    }
  },
  async markAsUnRead({ commit, rootGetters, state }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateForMarkAsUnread(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Email unread' : `${count} emails unread`;
    if (count == 0) {
      message = request.length === 1
        ? 'Email already unread'
        : `${request.length} emails already unread`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    }
    else {
      try {
        const response = await api.insert(`timeline/markAsUnRead`, { ids: calculatedRequest.ids });
        if (response) {
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'email',
              isLoading: false,
            });
            toast.done(toastId);
          });
          const user = rootGetters['user/user'];
          commit("MARK_AS_UNREAD", { request: calculatedRequest, user: user });
          return true;
        }
      } catch (error) {
        setTimeout(() => {
          toast.update(toastId, {
            render: "Failed to change emails flags",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        return false;
      }
    }
  },
  async toggleWaitingOn({ commit, dispatch }, request) {
    const toastId = toast.loading(
      'Please wait...', {
      theme: 'email',
    });
    const calculatedRequest = calculateToggleWaitingOn(state, request);
    let message = request.dueDate ? 'Email sets waiting-on' : "Email sets no waiting";
    try {
      const response = await api.insert(`timeline/toggleWaitingOn`, request)
      if (response) {
        setTimeout(() => {
          toast.update(toastId, {
            render: message,
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'email',
            isLoading: false,
          });
          toast.done(toastId);
        });
        commit("UPDATE_WAITING", { request: request, calculatedRequest: calculatedRequest });
        true
      }
    } catch (error) {
      setTimeout(() => {
        toast.update(toastId, {
          render: "Failed to change emails flags",
          autoClose: true,
          closeOnClick: true,
          closeButton: true,
          theme: 'email',
          isLoading: false,
        });
        toast.done(toastId);
      });
      return true;
    }
  },
  async fetchTimelines({ commit, state }, newRequestData) {
    let requestData = null;
    if (newRequestData === null) {
      requestData = {
        page: 1,
        pageSize: 50,
        order: 1,
        search: '',
        archived: state.requestData.archived,
        deleted: state.requestData.deleted,
        important: state.requestData.important,
        waitingOn: state.requestData.waitingOn,
        overdue: state.requestData.overdue,
        folder: state.requestData.folder,
      }
    } else {
      requestData = newRequestData;
    }
    commit('SET_REQUEST_DATA', requestData);
    commit('SET_LOADING', true);

    try {
      await fetchTimelinesWithRetry(commit, requestData, 0);
    } finally {
      commit('SET_LOADING', false);
    }

  },
  async fetchTimelineCount({ commit }) {
    try {
      const response = await api.get(`timeline/count`, {});
      if (response.data) {
        commit("SET_COUNT", response.data);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error fetching timeline count:', error);
      return false;
    }
  },
};
function countTotalAndUnseen(timelines) {
  let ids = timelines.map(i => i.id);
  let unseenCount = 0;
  let importantCount = 0;
  let waitingOnCount = 0;

  if (Array.isArray(timelines)) {
    timelines.forEach(item => {
      if (item.emailMessages && Array.isArray(item.emailMessages)) {
        unseenCount += item.emailMessages.filter(ti => !ti.seen).length;
      }
    });
  }
  importantCount = timelines.filter(i => i.isImportant).length;
  waitingOnCount = timelines.filter(i => i.waitingOn).length;

  return { ids, unseenCount, importantCount, waitingOnCount };
}
async function fetchTimelinesWithRetry(commit, requestData, retryCount) {
  try {
    let response = null;
    let requestParams = { ...requestData };
    if (requestParams.archived === false) {
      delete requestParams.archived;
    }
    if (requestParams.deleted === false) {
      delete requestParams.deleted;
    }
    if (requestParams.important === false) {
      delete requestParams.important;
    }
    if (requestParams.waitingOn === false) {
      delete requestParams.waitingOn;
    }
    if (requestParams.overdue === false) {
      delete requestParams.overdue;
    }
    if (requestParams.folder === null) {
      delete requestParams.folder;
    }
    response = await api.get('timeline', requestParams);
    if (response) {
      commit("SET_TIMELINES", response.data);
      commit('SET_LOADING', false);
      // let fetchEmailBodies = api.getEmailBody(`email/body`, { emailId: emailIds });
    }
  } catch (error) {
    console.error('Error fetching timelines:', error);
    if (retryCount < 3) {
      await new Promise(resolve => setTimeout(resolve, 10000));
      await fetchTimelinesWithRetry(commit, requestData, retryCount + 1);
    } else {
      console.error('Max retries reached. Failed to fetch timelines.');
    }
  }
}
function calculateForMarkAsUnread(state, request) {
  const timelinesInbox = state.timelines.items.filter(item => request.includes(item.id) && item.seen && !item.isArchive);
  const timelinesArchive = state.timelines.items.filter(item => request.includes(item.id) && item.seen && item.isArchive);
  let ids = [...timelinesInbox.map(i => i.id), ...timelinesArchive.map(i => i.id)];
  let inboxCount = 0;
  let archiveCount = 0;
  timelinesInbox.forEach(item => {
    if (item.emailMessages) {
      inboxCount += item.emailMessages.filter(ti => ti.seen).length;
    }
  });
  timelinesArchive.forEach(item => {
    if (item.emailMessages) {
      archiveCount += item.emailMessages.filter(ti => ti.seen).length;
    }
  });
  return { ids, inboxCount, archiveCount };
}
function calculateForMarkAsRead(state, request) {
  const timelinesInbox = state.timelines.items.filter(item => request.includes(item.id) && !item.seen && !item.isArchive);
  const timelinesArchive = state.timelines.items.filter(item => request.includes(item.id) && !item.seen && item.isArchive);
  let ids = [...timelinesInbox.map(i => i.id), ...timelinesArchive.map(i => i.id)];
  let inboxCount = 0;
  let archiveCount = 0;
  timelinesInbox.forEach(item => {
    if (item.emailMessages) {
      inboxCount += item.emailMessages.filter(ti => !ti.seen).length;
    }
  });
  timelinesArchive.forEach(item => {
    if (item.emailMessages) {
      archiveCount += item.emailMessages.filter(ti => !ti.seen).length;
    }
  });
  return { ids, inboxCount, archiveCount };
}
function calculateForMarkAsImportant(state, request) {
  const importantTimelines = state.timelines.items.filter(item => request.includes(item.id) && !item.isImportant);
  let ids = importantTimelines.map(i => i.id);
  return { ids };
}
function calculateForMarkAsNotImportant(state, request) {
  const importantTimelines = state.timelines.items.filter(item => request.includes(item.id) && item.isImportant);
  let ids = importantTimelines.map(i => i.id);
  return { ids };
}
function calculateForArchive(state, request) {
  const timelines = state.timelines.items.filter(item => request.includes(item.id) && !item.isArchive);
  let ids = timelines.map(i => i.id);
  let unSeenCount = 0;
  timelines.forEach(item => {
    if (item.emailMessages) {
      unSeenCount += item.emailMessages.filter(ti => !ti.seen).length;
    }
  });
  let importantCount = timelines.filter(i => i.isImportant).length;
  let waitingOnCount = timelines.filter(i => i.waitingOn).length;
  let overdueCount = timelines.filter(i => i.dueDate && new Date(i.dueDate) <= new Date()).length;
  return { ids, unSeenCount, importantCount, waitingOnCount, overdueCount };
}
function calculateForUnArchive(state, request) {
  const timelines = state.timelines.items.filter(item => request.includes(item.id) && item.isArchive);
  let ids = timelines.map(i => i.id);
  let unSeenCount = 0;
  timelines.forEach(item => {
    if (item.emailMessages) {
      unSeenCount += item.emailMessages.filter(ti => !ti.seen).length;
    }
  });
  let importantCount = timelines.filter(i => i.isImportant).length;
  let waitingOnCount = timelines.filter(i => i.waitingOn).length;
  let overdueCount = timelines.filter(i => i.dueDate && new Date(i.dueDate) <= new Date()).length;
  return { ids, unSeenCount, importantCount, waitingOnCount, overdueCount };
}
function calculateForMoveToInbox(state, request) {
  const timelines = state.timelines.items.filter(item => request.includes(item.id) && !item.isArchive);
  let ids = timelines.map(i => i.id);
  let unSeenCount = 0;
  timelines.forEach(item => {
    if (item.emailMessages) {
      unSeenCount += item.emailMessages.filter(ti => !ti.seen).length;
    }
  });
  let importantCount = timelines.filter(i => i.isImportant).length;
  let waitingOnCount = timelines.filter(i => i.waitingOn).length;
  let overdueCount = timelines.filter(i => i.dueDate && new Date(i.dueDate) <= new Date()).length;
  return { ids, unSeenCount, importantCount, waitingOnCount, overdueCount };
}
function calculateForDelete(state, request) {
  const timelinesInbox = state.timelines.items.filter(item => request.includes(item.id) && !item.isArchive);
  const timelinesArchive = state.timelines.items.filter(item => request.includes(item.id) && item.isArchive);
  const timelines = [...timelinesInbox, ...timelinesArchive]
  let ids = [...timelinesInbox.map(i => i.id), ...timelinesArchive.map(i => i.id)];
  let inboxCount = timelinesInbox.length;
  let inboxUnSeenCount = 0;
  let archiveCount = timelinesArchive.length;
  let archiveUnSeenCount = 0;
  timelinesInbox.forEach(item => {
    if (item.emailMessages) {
      inboxUnSeenCount += item.emailMessages.filter(ti => !ti.seen).length;
    }
  });
  timelinesArchive.forEach(item => {
    if (item.emailMessages) {
      archiveUnSeenCount += item.emailMessages.filter(ti => !ti.seen).length;
    }
  });
  let importantCount = timelines.filter(i => i.isImportant).length;
  let waitingOnCount = timelines.filter(i => i.waitingOn).length;
  let overdueCount = timelines.filter(i => i.dueDate && new Date(i.dueDate) <= new Date()).length;
  return { ids, inboxCount, inboxUnSeenCount, archiveCount, archiveUnSeenCount, importantCount, waitingOnCount, overdueCount };
}
function calculateToggleWaitingOn(state, request) {
  const timeline = state.timelines.items.find(t => t.id === request.timelineId);
  if (!timeline) return { addWaitingOn: false, addOverDue: false, removeOverDue: false };
  let addWaitingOn = !timeline.waitingOn;
  let addOverDue = false;
  let removeOverDue = false;
  const currentDateTime = new Date();
  const dueDateTime = request.dueDate ? new Date(request.dueDate) : null;
  const wasOverDue = timeline.dueDate && new Date(timeline.dueDate) <= currentDateTime;

  if (dueDateTime) {
    if (addWaitingOn) {
      addOverDue = dueDateTime <= currentDateTime;
    } else {
      if (dueDateTime > currentDateTime) {
        removeOverDue = true;
      }
    }
  } else {
    removeOverDue = wasOverDue;
  }
  return { addWaitingOn, addOverDue, removeOverDue };
}
export const mutations = {
  RESET_STATE(state, archived, deleted, important, waitingOn, overdue, folder) {
    Object.assign(state, initialState(archived, deleted, important, waitingOn, overdue, folder));
  },
  SET_LOADING(s, isLoading) {
    s.isLoading = isLoading;
  },
  SET_IsAllEmailsSynced(s, isAllEmailsSynced) {
    s.isAllEmailsSynced = isAllEmailsSynced;
  },
  SET_CONTACTS_FILTER(s, contacts) {
    s.contactsFilter = contacts;
    s.isContactsFilterLoading = false;
  },
  SET_COUNT(s, response) {
    s.timelineCounts = response
  },
  REMOVE(s, ids) {
    s.timelines.items = s.timelines.items.filter(item => !ids.includes(item.id));
  },
  REMOVE_FROM_CONTACT_FILER(s, id) {
    s.contactsFilter = s.contactsFilter.filter(item => item.id !== id);
  },
  ADD_TASK(s, task) {
    const timeline = s.timelines.items.find(t => t.id === task.timelineId);
    if (timeline) {
      timeline.timelineItems.push({
        id: task.id,
        assignDate: task.createDate,
        isDeleted: task.isDeleted,
        isImportant: task.isImportant,
        itemType: 2,
        timelineId: task.timelineId,
        title: task.title
      })
      timeline.tasks.push(task);
      timeline.taskCount++;
    }
  },
  ADD_NOTE(s, note) {
    const timeline = s.timelines.items.find(t => t.id === note.timelineId);
    if (timeline) {
      timeline.timelineItems.push({
        id: note.id,
        assignDate: note.createDate,
        isDeleted: note.isDeleted,
        isImportant: note.isImportant,
        itemType: 4,
        timelineId: note.timelineId,
        title: note.title
      })
      timeline.notes.push(note);
      timeline.noteCount++;
    }
  },
  ADD_EVENT(s, newEvent) {
    const event = newEvent.data[0]
    const timeline = s.timelines.items.find(t => t.id === event.timelineId);
    if (timeline) {
      timeline.timelineItems.push({
        id: event.id,
        assignDate: event.createDate,
        isDeleted: event.isDeleted,
        isImportant: event.isImportant,
        itemType: 3,
        timelineId: event.timelineId,
        title: event.summary
      })
      timeline.events.push(event);
      timeline.eventCount++;
    }
  },
  SET_ISCOLLAPSE(s, request) {
    const index = s.timelines.items.findIndex(item => item.id === request.id);
    if (index !== -1) {
      s.timelines.items[index].isCollapsed = !s.timelines.items[index].isCollapsed;

    };

  },

  SET_TIMELINE(s, { timeline, email }) {
    let timelineParticipants = timeline.participants.filter(p => p.email !== email)
    const unseenEmails = timeline.emailMessages.filter(e => !e.seen).length;
    let contactInContactFilter = s.contactsFilter.filter(c => timelineParticipants.some(p => p.id === c.id))
    contactInContactFilter.forEach(contact => {
      Object.assign(contact, {
        unSeenCount: Math.max(0, contact.unSeenCount - unseenEmails)
      });
    });
    const index = s.timelines.items.findIndex(item => item.id === timeline.id);
    if (index !== -1) {
      const foundItem = s.timelines.items[index];
      timeline.seen = true;
      timeline.isCollapsed = foundItem.isCollapsed;
      timeline.attachments = foundItem.attachments;
      timeline.attachmentsCount = foundItem.attachmentsCount;
      const unseenEmails = timeline.emailMessages.filter(e => !e.seen);
      s.timelineCounts.unSeenCount -= unseenEmails.length;
      timeline.emailMessages.forEach(e => e.seen = true);
      s.timelines.items[index] = timeline;
    }
    else {
      timeline.seen = true;
      timeline.isCollapsed = false;
      const unseenEmails = timeline.emailMessages.filter(e => !e.seen);
      s.timelineCounts.unSeenCount -= unseenEmails.length;
      timeline.emailMessages.forEach(e => e.seen = true);
      s.timelines.items.push(timeline);

    }
    s.isLoading = false;

  },
  SET_TIMELINES(s, data) {
    s.timelines = {};
    s.timelines = data;
  },

  SET_REQUEST_DATA(s, data) {
    s.requestData.page = data.page
    s.requestData.pageSize = data.pageSize
    s.requestData.order = data.order
    s.requestData.search = data.search
    s.requestData.archived = data.archived
    s.requestData.deleted = data.deleted
    s.requestData.important = data.important
    s.requestData.waitingOn = data.waitingOn
    s.requestData.overdue = data.overdue
    s.requestData.folder = data.folder
  },

  UPDATE_IMPORTANT(s, data) {
    data.response.forEach(response => {
      const timeline = s.timelines.items.find(t => t.id === response.id);
      if (timeline) {
        timeline.isImportant = data.important;
      }
    })
  },

  SET_ERROR_FOR_CONTACT(s, err) {
    s.contactsFilterLoadingError = err;
    s.isContactsFilterLoading = false;
  },
  SET_ERROR(s, err) {

  },
  MARK_AS_READ(s, { request, user }) {
    request.ids.forEach(id => {
      const timeline = s.timelines.items.find(t => t.id === id);
      if (timeline) {
        let timelineParticipants = timeline.participants.filter(p => p.email !== user.email)
        let timelineEmailsCount = timeline.timelineItems.filter(ti => ti.itemType === 1).length
        let contactInContactFilter = s.contactsFilter.filter(c => timelineParticipants.some(p => p.id === c.id))
        contactInContactFilter.forEach(contact => {
          Object.assign(contact, {
            unSeenCount: Math.max(0, contact.unSeenCount - timelineEmailsCount)
          });
        });
      }
    });
    if (!s.requestData.deleted) {
      s.timelineCounts.archiveUnSeenCount -= request.archiveCount;
      s.timelineCounts.unSeenCount -= request.inboxCount;
    }
    request.ids.forEach(id => {
      const timeline = s.timelines.items.find(t => t.id === id);
      if (timeline) {
        timeline.emailMessages.forEach(e => e.seen = true);
        timeline.seen = true;
      }
    })
  },
  MARK_AS_UNREAD(s, { request, user }) {
    request.ids.forEach(id => {
      const timeline = s.timelines.items.find(t => t.id === id);
      if (timeline) {
        let timelineParticipants = timeline.participants.filter(p => p.email !== user.email)
        let timelineEmailsCount = timeline.timelineItems.filter(ti => ti.itemType === 1).length
        let contactInContactFilter = s.contactsFilter.filter(c => timelineParticipants.some(p => p.id === c.id))
        contactInContactFilter.forEach(contact => {
          Object.assign(contact, {
            unSeenCount: Math.max(0, contact.unSeenCount + timelineEmailsCount)
          });
        });
      }
    });
    if (!s.requestData.deleted) {
      s.timelineCounts.archiveUnSeenCount += request.archiveCount;
      s.timelineCounts.unSeenCount += request.inboxCount;
    }
    request.ids.forEach(id => {
      const timeline = s.timelines.items.find(t => t.id === id);
      if (timeline) {
        timeline.emailMessages.forEach(e => e.seen = false);
        timeline.seen = false;
      }
    })
  },
  ERASE_EMAILS(s, ids) {
    s.timelines.items = s.timelines.items.filter(item => !ids.includes(item.id));
    s.timelineCounts.binCount -= ids.length;
  },
  MARK_AS_IMPORTANT(s, request) {
    if (!s.requestData.deleted) {
      s.timelineCounts.importantCount += request.ids.length;
    }
    request.ids.forEach(id => {
      const timeline = s.timelines.items.find(t => t.id === id);
      if (timeline) {
        timeline.isImportant = true;
      }
    })
  },
  MARK_AS_NOT_IMPORTANT(s, request) {
    if (!s.requestData.deleted) {
      s.timelineCounts.importantCount -= request.ids.length;
    }
    request.ids.forEach(id => {
      const timeline = s.timelines.items.find(t => t.id === id);
      if (timeline) {
        timeline.isImportant = false;
      }
    })
  },
  MARK_AS_ARCHIVE(s, { request, user }) {
    request.ids.forEach(id => {
      const timeline = s.timelines.items.find(t => t.id === id);
      if (timeline) {
        let participants = s.contactsFilter
          .filter(c => timeline.participants.some(p => p.id === c.id))
          .filter(c => c.email !== user.email);
        participants.forEach(contact => {
          Object.assign(contact, {
            totalCount: Math.max(0, contact.totalCount - request.ids.length),
            unSeenCount: Math.max(0, contact.unSeenCount - request.unSeenCount)
          });
        });
      }
    });
    if (s.requestData.overdue || s.requestData.waitingOn) {
    }
    else {
      s.timelines.items = s.timelines.items.filter(i => !request.ids.some(id => id === i.id));
    }
    s.timelineCounts.totalCount -= request.ids.length;
    s.timelineCounts.unSeenCount -= request.unSeenCount;
    s.timelineCounts.archiveCount += request.ids.length;
    s.timelineCounts.archiveUnSeenCount += request.unSeenCount;
    // s.timelineCounts.importantCount -= request.importantCount;
    // s.timelineCounts.waitingOnCount -= request.waitingOnCount;
    // if (s.timelineCounts.overdueCount > 0) {
    //   s.timelineCounts.overdueCount -= request.overdueCount;
    // }
  },
  MARK_AS_UNARCHIVE(s, { request, user }) {
    request.ids.forEach(id => {
      const timeline = s.timelines.items.find(t => t.id === id);
      if (timeline) {
        timeline.participants.forEach(participant => {
          if (participant.email === user.email) return;
          let contact = s.contactsFilter.find(c => c.id === participant.id);
          if (!contact) {
            contact = {
              id: participant.id,
              email: participant.email,
              name: participant.name,
              profileColor: participant.profileColor,
              profilePicUrl: participant.profilePicUrl,
              totalCount: 0,
              unSeenCount: 0
            };
            s.contactsFilter.unshift(contact);
          }
          const count = timeline.participants.filter(p => p.id === participant.id).length;
          const unseenCount = timeline.emailMessages
            .filter(email => !email.seen)
            .filter(email => timeline.participants.some(p => p.id === participant.id))
            .length;

          Object.assign(contact, {
            totalCount: Math.max(0, contact.totalCount + count),
            unSeenCount: Math.max(0, contact.unSeenCount + unseenCount)
          });
        });
      }
    });
    if (s.requestData.overdue || s.requestData.waitingOn) {
    }
    else {
      s.timelines.items = s.timelines.items.filter(i => !request.ids.some(id => id === i.id));
    }
    s.timelineCounts.totalCount += request.ids.length;
    s.timelineCounts.unSeenCount += request.unSeenCount;
    s.timelineCounts.archiveCount -= request.ids.length;
    s.timelineCounts.archiveUnSeenCount -= request.unSeenCount;
    // s.timelineCounts.importantCount -= request.importantCount;
    // s.timelineCounts.waitingOnCount -= request.waitingOnCount;
    // if (s.timelineCounts.overdueCount > 0) {
    //   s.timelineCounts.overdueCount -= request.overdueCount;
    // }
  },
  MOVE_TO_BIN(s, { request, user }) {
    request.ids.forEach(id => {
      const timeline = s.timelines.items.find(t => t.id === id);
      if (timeline) {
        let participants = s.contactsFilter
          .filter(c => timeline.participants.some(p => p.id === c.id))
          .filter(c => c.email !== user.email);
        participants.forEach(contact => {
          Object.assign(contact, {
            totalCount: Math.max(0, contact.totalCount - request.inboxCount),
            unSeenCount: Math.max(0, contact.unSeenCount - request.inboxUnSeenCount)
          });
        });
      }
    });
    s.timelines.items = s.timelines.items.filter(i => !request.ids.some(id => id === i.id));
    s.timelineCounts.binCount += request.ids.length;
    s.timelineCounts.totalCount -= request.inboxCount;
    s.timelineCounts.unSeenCount -= request.inboxUnSeenCount;
    s.timelineCounts.archiveCount -= request.archiveCount;
    s.timelineCounts.archiveUnSeenCount -= request.archiveUnSeenCount;
    s.timelineCounts.importantCount -= request.importantCount;
    s.timelineCounts.waitingOnCount -= request.waitingOnCount;
    if (s.timelineCounts.overdueCount > 0) {
      s.timelineCounts.overdueCount -= request.overdueCount;
    }
  },
  SET_ISCOLLAPSE_TRUE_All(state) {
    state.timelines.items.forEach((timeline) => {
      timeline.isCollapsed = true;
    });
  },
  ARCHIVE_TIMELINES_FROM_SIDE_PANEL(state, request) {
    state.timelineCounts.totalCount -= request.ids.length;
    state.timelineCounts.unSeenCount -= request.unSeenCount;
    state.timelineCounts.archiveCount += request.ids.length;
    state.timelineCounts.archiveUnSeenCount += request.unSeenCount;
    const contact = state.contactsFilter.find(t => t.id === request.contactId);
    if (contact) {
      contact.totalCount -= request.ids.length;
      contact.unSeenCount -= request.unSeenCount;
    }
  },
  DELETE_TIMELINES_FROM_SIDE_PANEL(state, request) {
    state.timelineCounts.totalCount -= request.ids.length;
    state.timelineCounts.binCount += request.ids.length;
    state.timelineCounts.unSeenCount -= request.unSeenCount;
    state.timelineCounts.importantCount -= request.importantCount;
    state.timelineCounts.waitingOnCount -= request.waitingOnCount;
    state.timelineCounts.overdueCount -= request.overdueCount;
    const contact = state.contactsFilter.find(t => t.id === request.contactId);
    if (contact) {
      contact.totalCount -= request.ids.length;
      contact.unSeenCount -= request.unSeenCount;
    }
  },
  MARK_AS_IMPORTANT_FROM_SIDE_PANEL(state, request) {
    state.timelineCounts.importantCount += request.ids.length;
  },
  MARK_AS_NOT_IMPORTANT_FROM_SIDE_PANEL(state, request) {
    state.timelineCounts.importantCount -= request.ids.length;
  },
  MARK_AS_READ_FROM_SIDE_PANEL(state, request) {
    state.timelineCounts.unSeenCount -= request.inboxCount;
    const contact = state.contactsFilter.find(t => t.id === request.contactId);
    if (contact) {
      contact.unSeenCount -= request.inboxCount;
    }
  },
  MARK_AS_UNREAD_FROM_SIDE_PANEL(state, request) {
    state.timelineCounts.unSeenCount += request.inboxCount;
    const contact = state.contactsFilter.find(t => t.id === request.contactId);
    if (contact) {
      contact.unSeenCount += request.inboxCount;
    }
  },
  TOGGLE_WAITINGON_TIMELINES_FROM_SIDE_PANEL(state, request) {
    const { addWaitingOn, addOverDue, removeOverDue } = request;
    if (state.timelineCounts) {
      state.timelineCounts.waitingOnCount = Math.max(0, state.timelineCounts.waitingOnCount + (addWaitingOn ? 1 : -1));
      if (addOverDue) {
        state.timelineCounts.overdueCount = Math.max(0, state.timelineCounts.overdueCount + 1);
      } else if (removeOverDue) {
        state.timelineCounts.overdueCount = Math.max(0, state.timelineCounts.overdueCount - 1);
      }
    }
  },
  UPDATE_WAITING(s, model) {
    const timeline = s.timelines.items.find(t => t.id === model.request.timelineId);
    if (timeline) {
      timeline.waitingOn = !timeline.waitingOn;
      timeline.dueDate = model.request.dueDate
    }
    if (!s.requestData.deleted) {
      if (s.timelineCounts) {
        s.timelineCounts.waitingOnCount = Math.max(0, s.timelineCounts.waitingOnCount + (model.calculatedRequest.addWaitingOn ? 1 : -1));
        if (model.calculatedRequest.addOverDue) {
          s.timelineCounts.overdueCount = Math.max(0, s.timelineCounts.overdueCount + 1);
        } else if (model.calculatedRequest.removeOverDue) {
          s.timelineCounts.overdueCount = Math.max(0, s.timelineCounts.overdueCount - 1);
        }
      }
    }

  },
}
export default {
  state,
  getters,
  actions,
  mutations
};