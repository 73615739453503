import api from '@/lib/services/general.service'
import 'vue-toast-notification/dist/theme-sugar.css'
import { toast } from 'vue3-toastify';


const initialState = () => ({
  tasks: {
    items: [],
    index: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: 0,
    hasNextPage: 0,
  },
  isLoading: true,
  taskCounts: {
    completedCount: 0,
    importantCount: 0,
    monthCount: 0,
    somedayCount: 0,
    todayCount: 0,
    totalCount: 0,
    waitingOnCount: 0,
    weekCount: 0,
  },
});
export const state = initialState();

export const getters = {
  tasks: s => s.tasks,

  importantTasks: (state) => {
    let items = state.tasks.items;
    return items
      .filter(task => task.isImportant && task.status !== 'done')
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  waitingOnTasks: (state) => {
    let items = state.tasks.items;
    return items
      .filter(task => task.status === "waitingon")
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  doneTasks: (state) => {
    let items = state.tasks.items;
    return items
      .filter(task => task.status === "done")
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  somedayTasks: (state) => {
    let items = state.tasks.items;
    return items
      .filter(task => task.status === "someday")
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  unscheduledTasks: (state) => {
    let items = state.tasks.items;
    return items
      .filter(task => task.dueDate == null && task.status !== 'someday' && task.status !== 'done')
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  todayTasks: (state) => {
    const currentDate = new Date();
    let items = state.tasks.items;
    return items
      .filter(task => {
        const taskDueDate = new Date(task.dueDate);
        return taskDueDate.toDateString() === currentDate.toDateString() &&
          taskDueDate >= currentDate && task.status !== 'done'
      })
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  tasksWithDate: (state) => {
    const currentDate = new Date();
    const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    let items = state.tasks.items;
    return items
      .filter(task => {
        const taskDueDate = new Date(task.dueDate);
        let taskDueDateOnly = new Date(taskDueDate.getFullYear(), taskDueDate.getMonth(), taskDueDate.getDate());
        return taskDueDateOnly >= currentDateOnly && task.status !== 'done';
      })
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  overdueTasks: (state) => {
    let items = state.tasks.items;
    const currentDate = new Date();
    return items
      .filter(task => {
        const taskDueDate = new Date(task.dueDate);
        // return task.dueDate != null && task.status !== 'done' && task.status !== 'someday' && task.status !== 'waitingon' && taskDueDate < currentDate;
        return task.dueDate != null && task.status !== 'someday' && task.status !== 'done' && taskDueDate < currentDate;
      })
      .sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));
  },
  nextWeekTasks: (state) => {
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;
    const next7DaysEndDate = new Date(currentDate.getTime() + oneWeek);
    let items = state.tasks.items;
    return items
      .filter(task => {
        const taskDueDate = new Date(task.dueDate);
        return taskDueDate <= next7DaysEndDate &&
          taskDueDate > currentDate && task.status !== 'done';
      })
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  nextMonthTasks: (state) => {
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneMonth = 30 * oneDay;
    //const oneWeek = 7 * oneDay;
    //const next7DaysEndDate = new Date(currentDate.getTime() + oneWeek);
    const next30DaysEndDate = new Date(currentDate.getTime() + oneMonth);
    let items = state.tasks.items;
    return items
      .filter(task => {
        const taskDueDate = new Date(task.dueDate);
        return taskDueDate <= next30DaysEndDate && taskDueDate > currentDate && task.status !== 'done';
      })
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  isLoading: s => s.isLoading,
  taskItems: s => s.taskItems,
  taskCounts: s => s.taskCounts
};
export const actions = {
  async fetchTasks({ commit, dispatch }, requestData) {
    return await api.get(`task`, requestData).then(response => {
      if (response) {
        const items = response.data.items.map(item => {
          if (item.dueDate != null) {
            const dueDate = new Date(item.dueDate)
            const localDueDate = new Date(dueDate.getTime() - (dueDate.getTimezoneOffset() * 60000))
            item.dueDate = localDueDate.toISOString()
          }
          if (item.remindDate != null) {
            const remindDate = new Date(item.remindDate)
            const localRemindDate = new Date(remindDate.getTime() - (remindDate.getTimezoneOffset() * 60000))
            item.remindDate = localRemindDate.toISOString()
          }
          return item;
        })

        response.data.items = items
        commit("SET_TASKS", response.data)
        dispatch('fetchTaskCount');
      }
      return true;
    });
  },
  async addTask({ commit, dispatch }, task) {
    const toastId = toast.loading(
      'Please wait...',
      {
        theme: 'task',
      }
    );
    try {
      const response = await api.insert('task', task);
      if (response) {
        if (task.timelineId) {
          dispatch('timeline/addTaskIntoTimeline', response.data, { root: true })
        }
        commit("ADD_TASK", response.data);
        setTimeout(() => {
          toast.update(toastId, {
            render: 'Task added',
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            theme: 'task',
            isLoading: false,
          });
          toast.done(toastId);
        });
        dispatch('fetchTaskCount');
        return true;
      }
      return false;

    } catch (error) {
      console.error('Error adding task:', error);
      return false;
    }
  },
  async fetchTaskCount({ commit }) {
    return await api.get(`task/count`, {}).then(response => {
      if (response) {
        commit("SET_COUNT", response.data);
      }
      return true;
    });
  },
  async updateStatus({ commit, dispatch }, request) {
    const toastId = toast.loading(
      'Please wait...',
      {
        theme: 'task',
      }
    );
    return api.insert(`task/updateStatus`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_TASK", response.data);
          setTimeout(() => {
            toast.update(toastId, {
              render: 'Task updated',
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'task',
              isLoading: false,
            });
            toast.done(toastId);
          });
          dispatch('fetchTaskCount');
          return true;
        }
        return false;
      });
  },
  async markAsImportant({ commit, dispatch, state }, request) {
    const toastId = toast.loading(
      'Please wait...',
      {
        theme: 'task',
      }
    );
    const calculatedRequest = calculateForMarkAsImportant(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Task sets as important' : `${count} tasks sets as important`;
    if (count == 0) {
      message = request.length === 1
        ? 'Task already important'
        : `${request.length} tasks already important`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'task',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    } else {
      return api.insert(`task/markAsImportant`, { ids: calculatedRequest.ids })
        .then((response) => {
          if (response) {
            commit("UPDATE_TASK", response.data);
            setTimeout(() => {
              toast.update(toastId, {
                render: message,
                autoClose: 1000,
                closeOnClick: true,
                closeButton: true,
                theme: 'task',
                isLoading: false,
              });
              toast.done(toastId);
            });
            dispatch('fetchTaskCount');
            return true;
          }
          return false;
        });
    }



  },
  async markAsNotImportant({ commit, dispatch, state }, request) {
    const toastId = toast.loading(
      'Please wait...',
      {
        theme: 'task',
      }
    );
    const calculatedRequest = calculateForMarkAsNotImportant(state, request);
    const count = calculatedRequest.ids.length;
    let message = count === 1 ? 'Task sets as not important' : `${count} tasks sets as not important`;
    if (count == 0) {
      message = request.length === 1
        ? 'Task already not important'
        : `${request.length} tasks already not important`;
      setTimeout(() => {
        toast.update(toastId, {
          render: message,
          autoClose: 1000,
          closeOnClick: true,
          closeButton: true,
          theme: 'task',
          isLoading: false,
        });
        toast.done(toastId);
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
      return true;
    }
    else {
      return api.insert(`task/markAsNotImportant`, { ids: calculatedRequest.ids })
        .then((response) => {
          if (response) {
            commit("UPDATE_TASK", response.data);
            setTimeout(() => {
              toast.update(toastId, {
                render: message,
                autoClose: true,
                closeOnClick: true,
                closeButton: true,
                theme: 'task',
                isLoading: false,
              });
              toast.done(toastId);
            });
            dispatch('fetchTaskCount');
            return true;
          }
          return false;
        });
    }

  },
  async deleteTask({ commit, dispatch }, request) {
    const count = request.length
    let message = ''
    const toastId = toast.loading(
      'Please wait...',
      {
        theme: 'task',
      }
    );
    if (count === 1) {
      message = 'Task deleted'
    } else {
      message = count + ' tasks deleted'
    }
    return await api.deleteWithRequest(`task/delete`, { ids: request })
      .then((response) => {
        if (response.data) {
          commit("REMOVE_TASK", request);
          setTimeout(() => {
            toast.update(toastId, {
              render: message,
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'task',
              isLoading: false,
            });
            toast.done(toastId);
          });
          dispatch('fetchTaskCount');
          return true;
        }
        return false;
      });
  },
  async editTask({ commit }, patchModel) {
    const toastId = toast.loading(
      'Please wait...',
      {
        theme: 'task',
      }
    );
    return await api.patch(`task`, patchModel[0], patchModel[1])
      .then((response) => {
        if (response) {
          commit("REMOVE_TASK", [response.data.id]);
          commit("ADD_TASK", response.data);
          setTimeout(() => {
            toast.update(toastId, {
              render: "Task updated",
              autoClose: true,
              closeOnClick: true,
              closeButton: true,
              theme: 'task',
              isLoading: false,
            });
            toast.done(toastId);
          });
          return true;
        }
        return false;
      });
  }
}
function calculateForMarkAsImportant(state, request) {
  const tasks = state.tasks.items.filter(item => request.includes(item.id) && !item.isImportant);
  let ids = tasks.map(i => i.id);
  return { ids };
}
function calculateForMarkAsNotImportant(state, request) {
  const tasks = state.tasks.items.filter(item => request.includes(item.id) && item.isImportant);
  let ids = tasks.map(i => i.id);
  return { ids };
}
export const mutations = {

  SET_TASKS(s, tasks) {
    s.tasks = tasks;
    s.isLoading = false;
  },
  ADD_TASK(s, task) {
    s.tasks.items.push(task)
    if (s.tasks.totalCount == 0) {
      s.tasks.totalCount++
      s.tasks.totalPages++
    }
  },
  SET_COUNT(s, response) {
    s.taskCounts = response
  },
  REMOVE_TASK(s, ids) {
    s.tasks.items = s.tasks.items.filter(item => !ids.includes(item.id));
  },
  UPDATE_TASK(s, response) {
    response.forEach(response => {
      const task = s.tasks.items.find(task => task.id === response.id);
      if (task) {
        task.isImportant = response.isImportant;
        task.status = response.status
        task.dueDate = response.dueDate;
        task.content = response.content
        task.title = response.title
      }
    });
  }
}
export default {
  state,
  getters,
  actions,
  mutations
};