import { toast } from 'vue3-toastify';
const initialState = () => ({
    entities: [
        // {
        //     menu: null,
        //     timelineId: null,
        //     timelineHeadLine: null,
        //     selectedDate: new Date(),
        //     replyTo: [],
        //     subject: null,
        //     replyToEmailId: null,
        //     contentHtml: '',
        //     eventData: null,
        // }
    ],
    my: false
});
export const state = initialState();
export const getters = {
    entities: state => state.entities,
}
export const actions = {
    addForm({ commit, rootGetters, state }, { timelineId, menu }) {
        const count = calculateCount(state);

        if (count >= 5) {
            toast("Cant open more than 5 creation form", {
                autoClose: 2000,
                theme: 'email',
            });
        }
        else {
            const timeline = rootGetters['timeline/getTimelineById'](timelineId);
            let entity = {
                id: crypto.randomUUID(),
                menu: menu,
                timelineId: timeline.id,
                timelineHeadLine: timeline.headLine,
                selectedDate: new Date(),
                replyTo: [],
                subject: timeline.emailMessages[0].subject,
                replyToEmailId: timeline.emailMessages[0].id,
                contentHtml: '',
                eventData: null,
                isMinimized: true,
                zIndex: state.entities.length + 1, // Start from 1 and increment
                data: null,
            }
            commit('ADD_FORM', entity);
            commit('SET_ACTIVE_ENTITY', entity.id);

        }
    },
    removeForm({ commit }, id) {
        commit('REMOVE_FORM', id);
    },
    toggleMinimize({ commit }, request) {
        commit('TOGGLE_MINIMIZE', request);
    },
    bringToFront({ commit }, id) {
        commit('BRING_TO_FRONT', id);
    },
    openEntity({ commit, state }, id) {
        commit('SET_ACTIVE_ENTITY', id);
    }
}
function calculateCount(state) {
    return state.entities.length
}
export const mutations = {
    ADD_FORM(state, entity) {
        state.entities.push(entity);
    },
    REMOVE_FORM(state, id) {
        state.entities = state.entities.filter(entity => entity.id !== id);

    },
    TOGGLE_MINIMIZE(state, request) {
        const entity = state.entities.find(entity => entity.id === request.entityId);

        if (entity) {
            entity.isMinimized = !entity.isMinimized;

            if (entity.isMinimized) {
                // If minimized, remove as active
                state.activeEntityId = null;
            } else {
                // If maximized, set as active
                state.activeEntityId = entity.id;
            }

            if (request.data) {
                entity.data = request.data;
            }

            // Check if all entities are minimized
            const allMinimized = state.entities.every(e => e.isMinimized);

            if (allMinimized) {
                // Set zIndex based on array order
                state.entities.forEach((e, index) => {
                    e.zIndex = index + 1;
                });
            }
        }
    },
    BRING_TO_FRONT(state, id) {
        const entity = state.entities.find(entity => entity.id === id);
        if (entity) {
            // Update the zIndex to bring the selected entity to the front
            entity.zIndex = Math.max(...state.entities.map(e => e.zIndex)) + 1;
        }
    },
    SET_ACTIVE_ENTITY(state, id) {
        const maxZIndex = Math.max(...state.entities.map(e => e.zIndex || 1), 1);

        state.entities = state.entities.map(entity => ({
            ...entity,
            isMinimized: entity.id !== id, // Open only this entity, close others
            zIndex: entity.id === id ? maxZIndex + 1 : entity.zIndex

        }));

        // Move the opened entity to the start of the array

    }
}
// requestData: {
//     page: 1,
//     pageSize: 50,
//     order: 1,
//     search: '',
//     archived: false,
//     deleted: false,
//     important: false,
//     waitingOn: false,
//     overdue: false,
//     folder: null,
//   },